import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import { Switch } from "@/components/ui/switch"

import {
  Box,
  Text,
  Radio,
  RadioGroup,
  Card,
  CardBody,
  Select,
  useToast,
  Grid,
  GridItem,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Table,
  TableContainer,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  TableCaption,
  Button,
  useDisclosure,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  ModalFooter,
  Stack
} from "@chakra-ui/react";

import { useStateContext } from "../ContextProvider/Context";
import { getMentorList, requestPayment } from "../Store/Actions/otherAction";
import g2Toast from "../Custom/g2Toast";
import isEmptyValue from "../Custom/isEmptyValue";
import isEmptyObject from "../Custom/isEmptyObject";

import Navbar from "../Components/Navbar/Navbar";
import UniversalLeftSideBar from "../Components/Common/UniversalLeftSideBar";

import PaymentForm4 from "../Components/Common/PaymentForm4";

import G2Button20 from "../Components/Common/G2Button20";
import { getAvailableSubscriptions, getChildUsers, getParentUsers, getUserSubscriptions, inviteChildUser, removeChildUser, setChildUserFunding, updateParentUser } from "../Store/Actions/onboardAction";
import { formatCurrency, formatDate, formatDateTime, formatPercentage } from "../helpers/formatters";
import { MdEmail, MdOutlinePaid, MdPaid } from "react-icons/md";
import { CgController } from "react-icons/cg";
import { FaCheckCircle, FaMoneyBill, FaRegCheckCircle, FaTimesCircle, FaTrash } from "react-icons/fa";
import G2ConfirmAlert from "../Components/Common/G2ConfirmAlert";
import { GrStatusUnknown } from "react-icons/gr";
import _ from "lodash";
import SubscriptionForm from "../Components/Subscription/SubscriptionForm";

import { Switch } from "@chakra-ui/switch"
// import { useToast } from '@chakra-ui/react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'

const options = {
  mode: "payment",
  amount: 100,
  currency: "aud",
  appearance: {
    /*...*/
  },
};


const BillingPage = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();
  const today = useSelector((state) => state.Auth.today);
  const user = useSelector((state) => state.Auth.user);
  const subscriptions = useSelector((state) => state.Onboard.subscriptions);
  const childUsers = useSelector((state) => state.Onboard.childUsers);
  const parentUsers = useSelector((state) => state.Onboard.parentUsers);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [type, setType] = useState(null);
  const [users, setUsers] = useState(0);


  useEffect(() => {
    dispatch(getAvailableSubscriptions());
    dispatch(getUserSubscriptions());
    dispatch(getChildUsers());
    dispatch(getParentUsers());
  }, []);

  const currentSubscription = useMemo(() => {
    const active = (subscriptions ?? []).filter(r => r.isActive);
    if (active.length == 0) {
      return null;
    }
    return active[0];
  }, [subscriptions]);

  const fundedChildUsers = useMemo(() => {
    return childUsers.filter(r => r.isFunded);
  }, [childUsers]);



  const calcPeriod = () => {
    const startDate = new Date(today);
    const endDate = new Date(user.expire);

    const differenceMs = endDate - startDate;

    let differenceDays = differenceMs / (1000 * 60 * 60 * 24) + 1;

    if (differenceDays < 0) {
      differenceDays = 0;
    }

    return Math.round(differenceDays);
  };

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isDeleteUserChildOpen, setIsDeleteUserChildOpen] = useState(null)
  const [addUserSubscription, setAddUserSubscription] = useState(null);
  const [invite, setInvite] = useState({ name: "", email: "" });
  const [inviteModelOpen, setInviteModelOpen] = useState(false);
  const[openPopUp , setOpenPopUp] = useState(false);
  const [features, setFeatures] = useState({
    messaging: true,
    create: true,
    marketplace: true,
    connections: true,
    competitions: true,
    events: true,
    jobs: true,
    affiliate: true,
  });

  const setValue = function (object, name, value, set) {
    let temp = _.cloneDeep(object);
    temp = _.set(temp, name, value);
    set(temp);
  }


  const getParentUserStatus = (r) => {
    if (r.accepted == null && r.rejected == null) {
      return "Waiting";
    }

    if (r.rejected != null) {
      return "Off"
    }

    if (r.accepted != null) {
      if (r.isFunded) {
        return "Full"
      } else {
        return "On"
      }
    }

    return "???";
  };

  const getChildUserStatus = (r) => {
    if (r.isVerified && !r.accepted && !r.rejected) {
      return <GrStatusUnknown color="var(--orange)" />;
    }

    if (r.rejected) {
      return <FaTimesCircle color="var(--red)" />
    }

    if (r.accepted) {
      return <FaCheckCircle color="var(--green)" />;
    }

    return "???";
  };
  const handleOpenPopUp = () => {
    setOpenPopUp(true)
  }
  
  const handleToggle = (feature) => {
    setFeatures((prevState) => ({
      ...prevState,
      [feature]: !prevState[feature],
    }));
  };
  const handleSave = async () => {
    try {
      // Example API URL, replace with your actual API endpoint
      // const apiUrl = 'https://your-api-endpoint.com/save-features';
      
      // Payload to send to the API
      const payload = {
        features,
      };
      console.log(payload , "payload")
      // API request using axios (you can use fetch instead if needed)
      // const response = await axios.post(apiUrl, payload);
      
      // Handle successful response
      // if (response.status === 200) {
        toast({
          title: 'Features Updated Successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      // }
    } catch (error) {
      // Handle error case
      // toast({
      //   title: 'Error updating features',
      //   description: error.message,
      //   status: 'error',
      //   duration: 9000,
      //   isClosable: true,
      // });
    }
  };

  
 
  return (
    <Box
      minH="100vh"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}`}}
    >
      <Navbar title="Billing" />
      <Box w="100%" className="flex-box" >
        <Box className="universal-leftBar-w">
          <Box w="100%" h="100vh" className="sticky top-0">
            <UniversalLeftSideBar />
          </Box>
        </Box>
        <Box className="universal-body-w flex" style={{marginLeft:"-50px"}}>
      {/* Left side with Subscription table and other content */}
      <Box w="80%" display="flex" className="setting-box py-[50px] flex-box">
        <Box w="100%" className="market-bucket-left p-[20px]">
          <Box w="100%" className="flex mt-[50px]">
            <Text w="210px" fontSize="20px" className="font-semibold">Current Date:</Text>
            <Text fontSize="20px">{formatDate(today)}</Text>
          </Box>

          <Box w="100%" className="flex mt-[10px]">
            <Text w="210px" fontSize="20px" className="font-semibold">Expiration Date:</Text>
            <Text fontSize="20px">{formatDate(user.expire)}</Text>
            <Text fontSize="20px" className="ml-[10px]">({calcPeriod() + " days left"})</Text>
          </Box>

          {user.type !== 'Personal' && (
            <Box w="100%" className="flex mt-[10px]">
              <Text w="210px" fontSize="20px" className="font-semibold">Current Users:</Text>
              <Text fontSize="20px">{currentSubscription?.totalUserCount ?? 0}</Text>
            </Box>
          )}

          <Box className="my-[50px]">
            <Card w="90%" h="100%" className="py-[30px] px-[10px]" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
              <HStack justifyContent="space-between">
                <Text p="15px" fontSize="20px" className="font-semibold">Subscriptions</Text>
              </HStack>
              <TableContainer>
                <Table variant="striped" size="sm">
                  <Thead>
                    <Tr>
                      <Th p="2">Period</Th>
                      <Th p="2">Name</Th>
                      <Th p="2">Users</Th>
                      <Th p="2">Cost</Th>
                      <Th p="2">Status</Th>
                      <Th p="2">Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {subscriptions.map(r => (
                      <Tr key={r.id} border={r.isActive ? "2px solid var(--orange)" : ""}>
                        <Td p="2">{formatDate(r.start)} to {formatDate(r.end)}</Td>
                        <Td p="2">{r.name}, {r.description}</Td>
                        <Td p="2">{r.users}{r.varianceUserCount > 0 && <Text display="inline"> + ({r.varianceUserCount})</Text>}</Td>
                        <Td p="2">{formatCurrency(r.cost)}{r.varianceUserCount > 0 && <Text display="inline"> + ({formatCurrency(r.varianceUserCount * r.additionalUsers)})</Text>}</Td>
                        <Td p="2">
                          {r.confirmed !== 0 && <MdPaid color="var(--green)" />}
                          {r.confirmed !== 1 && <MdOutlinePaid color="var(--red)" />}
                        </Td>
                        <Td p="2">
                          {r.additionalUsers !== null && r.remainingPercentage > 0 && <Button size="sm" onClick={() => { setAddUserSubscription(r); onOpen(); }}>Add Users</Button>}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Modal size="lg" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Add Users</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <VStack>
                      <HStack w="100%" justifyContent="space-between">
                        <Text fontWeight="bold">Period</Text>
                        <Box>{formatDate(addUserSubscription?.start)} to {formatDate(addUserSubscription?.end)}</Box>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between">
                        <Text fontWeight="bold">Name</Text>
                        <Box>{addUserSubscription?.name}, {addUserSubscription?.description}</Box>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between">
                        <Text fontWeight="bold">Current User Count</Text>
                        <Box>{addUserSubscription?.users}</Box>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between">
                        <Text fontWeight="bold">New User Count</Text>
                        <Box>{parseInt(addUserSubscription?.users) + parseInt(users)}</Box>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between" borderTop="1px solid var(--orange)" marginTop={"15px"} paddingTop="15px">
                        <Text fontWeight="bold">Cost Per New User</Text>
                        <Box>{formatCurrency(addUserSubscription?.additionalUsers)}</Box>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between">
                        <Text fontWeight="bold">Additional Users</Text>
                        <Box>
                          <NumberInput w="60px" value={users} onChange={(value) => setUsers(value)} min={0} max={100}>
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </Box>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between">
                        <Text fontWeight="bold">Pro-rata Rate</Text>
                        <Box>{formatPercentage(addUserSubscription?.remainingPercentage)}</Box>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between">
                        <Text fontWeight="bold">Total</Text>
                        <Box>{formatCurrency(addUserSubscription?.additionalUsers * addUserSubscription?.remainingPercentage * users)}</Box>
                      </HStack>
                      {users > 0 && (
                        <Box w="100%" borderTop="1px solid var(--orange)" marginTop={"15px"} paddingTop="15px">
                          <Elements stripe={stripePromise} options={options}>
                            <PaymentForm4 subscriptionId={addUserSubscription?.id} users={users} isVariance />
                          </Elements>
                        </Box>
                      )}
                    </VStack>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Card>
          </Box>

          {user.type != 'Personal' && 
              <Box className="my-[50px]">
                <Card w="90%" h="100%" className="py-[30px] px-[10px]" style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
                  {
                    //JSON.stringify(childUsers)
                  }
                  <HStack justifyContent="space-between">
                    <Text p="15px" fontSize="20px" className="font-semibold">
                      Users {fundedChildUsers.length}/{currentSubscription?.totalUserCount ?? 0}
                      {fundedChildUsers.length > (currentSubscription?.totalUserCount ?? 0) && <Text color="var(--red)">Note, Funding More Users than allowed</Text>}
                    </Text>
                    <Button size="sm" onClick={() => { setInviteModelOpen(true) }}>Invite</Button>
                  </HStack>
                  <TableContainer>
                    <Table variant="striped" size="sm">
                      <Thead>
                        <Tr>
                            <Th p="2">Name</Th>
                            <Th p="2">Email</Th>
                            <Th p="2">Active</Th>
                            <Th p="2">Sponsored</Th>
                            <Th p="2">Action</Th>
                            <Th p="2">Controls</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {childUsers.map(r =>
                          <Tr key={r.id} border={r.isActive ? "2px solid var(--orange)" : ""}>
                            <Td p="2">{r.name}</Td>
                            <Td p="2">{r.email}</Td>
                            <Td p="2">{getChildUserStatus(r)}</Td>
                            <Td p="2">
                              {r.isFunded == 1 && <FaCheckCircle color="var(--green)" />}
                              {r.isFunded == 0 && <FaRegCheckCircle color="var(--red)" />}
                            </Td>
                            <Td p="2">
                              {r.parentInitiated == 0 && r.accepted == null && <Button size="sm" onClick={() => dispatch(updateParentUser(r.id, 1))}>Accept</Button>}
                              {r.isVerified == 1 && <Button size="sm" onClick={() => { dispatch(setChildUserFunding(r.userId, !r.isFunded)) }}><FaMoneyBill /></Button>}
                              {r.isVerified == 0 && <Button size="sm" onClick={() => { }}><MdEmail /></Button>}
                              <Button size="sm" onClick={() => { setIsDeleteUserChildOpen(r.userId) }}><FaTrash /></Button>
                            </Td>
                            <Td p="2">
                              <Button size="sm"
                              onClick={handleOpenPopUp}
                              >
                              <CgController/></Button>
                              </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <G2ConfirmAlert isOpen={isDeleteUserChildOpen != null} onClose={() => setIsDeleteUserChildOpen(null)} handleOkClick={() => { setIsDeleteUserChildOpen(null); dispatch(removeChildUser(isDeleteUserChildOpen)) }} />
                </Card>
                </Box>
              }
        </Box>
      </Box>

      {/* Right section with Subscription Form */}
      <Box w="30%" style={{marginLeft:"-100px" , marginTop:"120px"}}>
        <SubscriptionForm />
        <Button w="100%" style={{marginLeft:"10px" , marginTop:"50px" , backgroundColor:"white" , color:"orange" , border:"solid 1px black" }}>Upgrade Subcription</Button>
      </Box>
    </Box>
      </Box>
      <Modal size="lg" isOpen={inviteModelOpen} onClose={() => setInviteModelOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <HStack w="100%" justifyContent="space-between">
                <Text fontWeight="bold">Name</Text>
                <Box w="100%"><Input value={invite.name} onChange={(e) => setValue(invite, 'name', e.target.value, setInvite)} /></Box>
              </HStack>
              <HStack w="100%" justifyContent="space-between">
                <Text fontWeight="bold">Email</Text>
                <Box w="100%"><Input type="email" value={invite.email} onChange={(e) => setValue(invite, 'email', e.target.value, setInvite)} /></Box>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => { setInviteModelOpen(false); dispatch(inviteChildUser(invite.name, invite.email, user.type != 'Personal')) }}>Invite</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal size="lg" isOpen={openPopUp} onClose={() => setOpenPopUp(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Features Access </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              {/* Messaging Feature */}
              <Grid templateColumns="1fr 1fr 1fr" gap={4} alignItems="center">
                <GridItem>
                  <Text fontWeight="bold">Messaging</Text>
                </GridItem>
                <GridItem>
                  <Switch
                    colorScheme={features.messaging ? 'green' : 'gray'}
                    isChecked={features.messaging}
                    onChange={() => handleToggle('messaging')}
                    size="md"
                    width="60px"
                    height="40px"
                  />
                </GridItem>
                <GridItem>
                  <Popover>
                    <PopoverTrigger>
                      <Button size="sm" width="200px">Feature Description</Button>
                    </PopoverTrigger>
                    <PopoverContent width="250px" height="50px">
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>This feature allows users to send and receive messages.</PopoverHeader>
                    </PopoverContent>
                  </Popover>
                </GridItem>
              </Grid>

              {/* Create Feature */}
              <Grid templateColumns="1fr 1fr 1fr" gap={4} alignItems="center">
                <GridItem>
                  <Text fontWeight="bold">Create</Text>
                </GridItem>
                <GridItem>
                  <Switch
                    colorScheme={features.create ? 'green' : 'gray'}
                    isChecked={features.create}
                    onChange={() => handleToggle('create')}
                    size="md"
                    width="60px"
                    height="40px"
                  />
                </GridItem>
                <GridItem>
                  <Popover>
                    <PopoverTrigger>
                      <Button size="sm" width="200px">Feature Description</Button>
                    </PopoverTrigger>
                    <PopoverContent width="250px" height="50px">
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Allows users to create and upload courses.</PopoverHeader>
                    </PopoverContent>
                  </Popover>
                </GridItem>
              </Grid>

              {/* MarketPlace Feature */}
              <Grid templateColumns="1fr 1fr 1fr" gap={4} alignItems="center">
                <GridItem>
                  <Text fontWeight="bold">MarketPlace</Text>
                </GridItem>
                <GridItem>
                  <Switch
                    colorScheme={features.marketplace ? 'green' : 'gray'}
                    isChecked={features.marketplace}
                    onChange={() => handleToggle('marketplace')}
                    size="md"
                    width="60px"
                    height="40px"
                  />
                </GridItem>
                <GridItem>
                  <Popover>
                    <PopoverTrigger>
                      <Button size="sm" width="200px">Feature Description</Button>
                    </PopoverTrigger>
                    <PopoverContent width="250px" height="50px">
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Access to browse, buy, and sell content.</PopoverHeader>
                    </PopoverContent>
                  </Popover>
                </GridItem>
              </Grid>

              {/* Connections Feature */}
              <Grid templateColumns="1fr 1fr 1fr" gap={4} alignItems="center">
                <GridItem>
                  <Text fontWeight="bold">Connections</Text>
                </GridItem>
                <GridItem>
                  <Switch
                    colorScheme={features.connections ? 'green' : 'gray'}
                    isChecked={features.connections}
                    onChange={() => handleToggle('connections')}
                    size="md"
                    width="60px"
                    height="40px"
                  />
                </GridItem>
                <GridItem>
                  <Popover>
                    <PopoverTrigger>
                      <Button size="sm" width="200px">Feature Description</Button>
                    </PopoverTrigger>
                    <PopoverContent width="250px" height="50px">
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Access to connect with mentors or businesses.</PopoverHeader>
                    </PopoverContent>
                  </Popover>
                </GridItem>
              </Grid>

              {/* Competitions Feature */}
              <Grid templateColumns="1fr 1fr 1fr" gap={4} alignItems="center">
                <GridItem>
                  <Text fontWeight="bold">Competitions</Text>
                </GridItem>
                <GridItem>
                  <Switch
                    colorScheme={features.competitions ? 'green' : 'gray'}
                    isChecked={features.competitions}
                    onChange={() => handleToggle('competitions')}
                    size="md"
                    width="60px"
                    height="40px"
                  />
                </GridItem>
                <GridItem>
                  <Popover>
                    <PopoverTrigger>
                      <Button size="sm" width="200px">Feature Description</Button>
                    </PopoverTrigger>
                    <PopoverContent width="250px" height="50px">
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Ability to view and participate in competitions.</PopoverHeader>
                    </PopoverContent>
                  </Popover>
                </GridItem>
              </Grid>

              {/* Events Feature */}
              <Grid templateColumns="1fr 1fr 1fr" gap={4} alignItems="center">
                <GridItem>
                  <Text fontWeight="bold">Events</Text>
                </GridItem>
                <GridItem>
                  <Switch
                    colorScheme={features.events ? 'green' : 'gray'}
                    isChecked={features.events}
                    onChange={() => handleToggle('events')}
                    size="md"
                    width="60px"
                    height="40px"
                  />
                </GridItem>
                <GridItem>
                  <Popover>
                    <PopoverTrigger>
                      <Button size="sm" width="200px">Feature Description</Button>
                    </PopoverTrigger>
                    <PopoverContent width="250px" height="50px">
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Access to view and participate in events.</PopoverHeader>
                    </PopoverContent>
                  </Popover>
                </GridItem>
              </Grid>

              {/* Jobs Feature */}
              <Grid templateColumns="1fr 1fr 1fr" gap={4} alignItems="center">
                <GridItem>
                  <Text fontWeight="bold">Jobs</Text>
                </GridItem>
                <GridItem>
                  <Switch
                    colorScheme={features.jobs ? 'green' : 'gray'}
                    isChecked={features.jobs}
                    onChange={() => handleToggle('jobs')}
                    size="md"
                    width="60px"
                    height="40px"
                  />
                </GridItem>
                <GridItem>
                  <Popover>
                    <PopoverTrigger>
                      <Button size="sm" width="200px">Feature Description</Button>
                    </PopoverTrigger>
                    <PopoverContent width="250px" height="50px">
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Access to view jobs.</PopoverHeader>
                    </PopoverContent>
                  </Popover>
                </GridItem>
              </Grid>

              {/* Affiliate Feature */}
              <Grid templateColumns="1fr 1fr 1fr" gap={4} alignItems="center">
                <GridItem>
                  <Text fontWeight="bold">Affiliate</Text>
                </GridItem>
                <GridItem>
                  <Switch
                    colorScheme={features.affiliate ? 'green' : 'gray'}
                    isChecked={features.affiliate}
                    onChange={() => handleToggle('affiliate')}
                    size="md"
                    width="60px"
                    height="40px"
                  />
                </GridItem>
                <GridItem>
                  <Popover>
                    <PopoverTrigger>
                      <Button size="sm" width="200px">Feature Description</Button>
                    </PopoverTrigger>
                    <PopoverContent width="250px" height="50px">
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Ability to view and manage affiliate features.</PopoverHeader>
                    </PopoverContent>
                  </Popover>
                </GridItem>
              </Grid>
            </VStack>
          </ModalBody>
          <ModalFooter>
          <Button onClick={handleSave}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
    </Box >
  );
};

export default BillingPage;
