import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  Card,
  CardBody,
  Select,
  Progress,
  Circle,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";

import { ArrowLeftIcon, ArrowRightIcon, LockIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";
import {
  getGoalList,
  addGoal,
  updateGoal,
  deleteGoal,
} from "../../Store/Actions/goalAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

// import AddModal from "./AddModal";

import G2Button10 from "../Common/G2Button10";
import G2Button20 from "../Common/G2Button20";
import G2Button21 from "../Common/G2Button21";
import G2Button23 from "../Common/G2Button23";

import event from "../../assets/academy/event.png";
import { getServerUrl } from "../../helpers/location";
import { actionEvent } from "../../Store/Actions/academyAction";
import { formatDateTime } from "../../helpers/formatters";
import { getFileUrl } from "../../helpers/general";

const EventViewForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const event = useSelector((state) => state.Academy.event);
  const user = useSelector((state) => state.Auth.user);

  const [isGoalDopen, setIsGoalDopen] = useState(false);

  const token = getToken();

  useEffect(() => {
    if (isEmptyValue(event?._id)) {
      navigate("/academy/event");
    }
  }, [event]);

  const handleBackClick = () => {
    navigate("/academy/event");
  };

  const RSVPClick = () => {
    dispatch(actionEvent(toast, event?._id, 1, null));
  };

  return (
    <Box
      w="100%"
      h="100%"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box
        w="100%"
        h="80px"
        bg={G2_color_2}
        className="flex centering-h px-[30px] shadow relative"
      >
        <Text
          fontSize="20px"
          className="font-semibold mr-[10px] text-[var(--orange)]"
        >
          Events Boards
        </Text>
        <Box className="absolute right-[30px]">
          <G2Button20
            widthVal="80px"
            heightVal="35px"
            text="Back"
            onClick={handleBackClick}
          />
        </Box>
      </Box>
      <Box w="100%" className="p-[30px]">
        <Box w="100%" className="centering-w">
          <Image w="300px" src={getFileUrl(event?.photo)} className="block" />
        </Box>
        <Box w="100%" className="centering-w">
          <Box w="300px" className="mt-[10px]">
            <Box w="100%" className="flex justify-between">
              <Box
                bg="#334F72"
                className="px-[10px] py-[5px] text-[12px] text-white"
              >
                {event?.category}
              </Box>
            </Box>
            <Text
              fontSize="20px"
              className="mt-[10px] font-semibold text-center"
            >
              {event?.name}
            </Text>
            {event.userId == user._id && (
              <>
                <Text>Invited: {event.invitedUsers}</Text>
                <Text>Accepted: {event.acceptedUsers}</Text>
                <Text>Rejected: {event.rejectedUsers}</Text>
              </>
            )}
            <Text fontSize="16px" className="text-center">
              {event?.companyWebsite}
            </Text>
            <Text fontSize="16px" className="text-center">
              {event?.location}
            </Text>
            <Text
              fontSize="16px"
              className="text-center font-semibold text-[#d86655]"
            >
              {event?.date} {event?.time}
            </Text>
            <Text fontSize="16px" className="text-center">
              {event?.description}
            </Text>
            <Box w="100%" className="mt-[10px] centering-w">
              {(event?.accepted ?? null) != null &&
                (event?.rejected ?? null) != null && (
                  <G2Button20
                    widthVal="100px"
                    heightVal="35px"
                    text="RSVP"
                    onClick={RSVPClick}
                  />
                )}
              {(event?.accepted ?? null) != null && (
                <Text>Accepted {formatDateTime(event.accepted)}</Text>
              )}
              {(event?.rejected ?? null) != null && (
                <Text>Rejected {formatDateTime(event.rejected)}</Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EventViewForm;
