import React, { useState, useEffect, useMemo, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Container,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import styled from "styled-components";

import "../App.css";

import { useStateContext } from "../ContextProvider/Context";
import { goToLogin } from "../Store/Actions/authAction";
import { getToken, verifyToken } from "../utils";

import Navbar from "../Components/Navbar/Navbar";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getSystemContent } from "../Store/Actions/otherAction";
import { useSelector } from "react-redux";
import { getServerUrl } from "../helpers/location";
import background from "../assets/landing_page/background.png";
import UTradieText from "../Components/Common/UTradieText";
import twitter from "../assets/social/twitter.png";
import facebook from "../assets/social/facebook.png";
import instagram from "../assets/social/instagram.png";
import tiktok from "../assets/social/tiktok.png";
import spotify from "../assets/social/spotify.png";
import youtube from "../assets/social/youtube.png";
import dashboardImage from "../assets/landing_page/image12.png";
import UTradie_Logo from "../assets/landing_page/UtradieLogo.png";
import G2Button27 from "../Components/Common/G2Button27";
import PricingTiers from "../Components/Common/PricingTier";
import { useLocation } from "react-router-dom";

const StyledText = styled.div`
  font-family: "Trebuchet MS";
`;

const Carousel = ({ items, children, settings }) => {
  const sortedItems = useMemo(() => {
    return items.sort((a, b) => a.index - b.index);
  }, [items]);

  return (
    <>
      <div className="slider-container">
        <Slider {...settings}>
          {sortedItems.map((item, index) => {
            return (
              <Box key={index} w="100%">
                {children(item, index)}
              </Box>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

const LandingPage = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const location = useLocation();
  const pricingTiersRef = useRef(null);

  // const social = [twitter, facebook, instagram, tiktok, spotify, youtube];

  const social = [
    { src: facebook, url: "https://www.facebook.com/join.UTRADIE/" },
    { src: instagram, url: "https://www.instagram.com/join.utradie/" },
    { src: twitter, url: "https://twitter.com/utradie" },
    // { src: LinkedIn, url: "https://www.linkedin.com/company/grow2company" },
    { src: youtube, url: "https://www.youtube.com/@utradie" },
    { src: spotify, url: "https://www.reddit.com/user/UTRADIE/" },
    { src: tiktok, url: "https://www.tiktok.com/@utradie" },
  ];

  const systemContent = useSelector((state) => state.Onboard.systemContent);
  const navigate = useNavigate();

  const token = getToken();

  const dispatch = useDispatch();

  const [screenSize, setScreenSize] = useState(1920);

  const isLargeScreen = screenSize > 1024;

  useEffect(() => {
    // Fetch system content
    dispatch(getSystemContent());

    // Check location state to determine if scroll action is needed
    if (location.state && location.state.scrollTo === "pricingTiers") {
      if (pricingTiersRef.current) {
        // Add a 1-second delay before scrolling
        const scrollTimeout = setTimeout(() => {
          pricingTiersRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }, 100);

        // Cleanup timeout on component unmount or on dependency change
        return () => clearTimeout(scrollTimeout);
      }
    }
  }, [dispatch, location.state]);

  useEffect(() => {
    setScreenSize(window.innerWidth);
    window.addEventListener("resize", setScreenSize);
    dispatch(getSystemContent());
  }, [window.innerWidth]);

  const scrollToPricingTiers = () => {
    if (pricingTiersRef.current) {
      pricingTiersRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const items = useMemo(() => {
    return systemContent.reduce((s, r) => {
      if (s[r.type] == undefined) {
        s[r.type] = [];
      }
      s[r.type].push(r);
      return s;
    }, {});
  }, [systemContent]);

  const settings = {
    main: {
      dots: true,
      arrows: true,
      fade: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 20000,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    sponsors: {
      dots: true,
      arrows: true,
      fade: false,
      infinite: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 18000,
      slidesToShow: isLargeScreen ? 3 : 1,
      slidesToScroll: isLargeScreen ? 3 : 1,
    },
    testimonials: {
      dots: true,
      arrows: true,
      fade: false,
      infinite: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 16000,
      slidesToShow: isLargeScreen ? 3 : 1,
      slidesToScroll: isLargeScreen ? 3 : 1,
    },
  };

  return (
    <Box>
      <Navbar title="Home" isLanding={true} />
      <VStack w="100%">
        <Box
          className="block lg:flex"
          flexDirection={isLargeScreen ? "row" : "column"}
          w="100%"
          p="20px"
          justifyContent="center"
        >
          <Box
            flex="1"
            p="20px"
            px="30px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src={dashboardImage}
              alt="Dashboard"
              w="100%"
              maxW="800px"
              objectFit="contain"
            />
          </Box>

          <Box
            flex="1"
            p="20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Image src={UTradie_Logo} alt="UTradie Logo" w="200px" mb="5px" />
            <Text
              mb="10px"
              color="var(--orange)"
              className="font-bold text-6xl"
            >
              BUILD SKILLS &<br />
              SHAPE YOUR
              <br />
              FUTURE
            </Text>

            <Text className="text-3xl text-dark-blue mb-10">
              <span className="text-orange-600 font-bold">U</span>
              TRADIE provides a community for tradies.
            </Text>
            <G2Button27
              text="Create an account"
              onClick={scrollToPricingTiers}
            />
          </Box>
        </Box>

        <Box
          p="50px"
          w="100%"
          backgroundColor=" var(--orange)"
          backgroundImage={background}
          justifyContent="center"
          alignContent="center"
        >
          <Box
            w="75%"
            justifyContent="center"
            alignContent="center"
            marginX="auto"
          >
            <Carousel items={items["main"] ?? []} settings={settings.main}>
              {(item) => {
                return (
                  <Box w="100%">
                    {isLargeScreen && (
                      <HStack alignContent="center" justifyContent="center">
                        {item.hasImage && (
                          <Image
                            height="100%"
                            width="auto"
                            src={`${getServerUrl()}/index.php?class=ControllerAccess&method=getSystemContentImage&id=${
                              item.id
                            }`}
                            alt={item.title}
                          />
                        )}
                        <VStack w="100%" p="5px">
                          <Box
                            className="brand-text"
                            fontSize={isLargeScreen ? "40px" : "20px"}
                            color="white"
                          >
                            {item.title}
                          </Box>
                          <Box textAlign="justify">{item.body}</Box>
                        </VStack>
                      </HStack>
                    )}
                    {!isLargeScreen && (
                      <VStack alignContent="center" justifyContent="center">
                        {item.hasImage && (
                          <Image
                            height="100%"
                            width="auto"
                            src={`${getServerUrl()}/index.php?class=ControllerAccess&method=getSystemContentImage&id=${
                              item.id
                            }`}
                            alt={item.title}
                          />
                        )}
                        <VStack w="100%" p="5px">
                          <Box
                            className="brand-text"
                            fontSize={isLargeScreen ? "40px" : "20px"}
                            color="white"
                          >
                            {item.title}
                          </Box>
                          <Box textAlign="justify">{item.body}</Box>
                        </VStack>
                      </VStack>
                    )}
                  </Box>
                );
              }}
            </Carousel>
          </Box>
        </Box>

        {/* show that component here */}
        <Box ref={pricingTiersRef}>
          <PricingTiers />
        </Box>

        {(items["testimonials"] ?? []).length > 0 && (
          <Box p="50px" w="75%">
            <HStack
              justifyContent={isLargeScreen ? "space-between" : "center"}
              m="30px"
              wrap="wrap"
            >
              <Box fontWeight="bold">Testimonials:</Box>
              <Button>Submit a Testimonial</Button>
            </HStack>
            <Carousel
              items={items["testimonials"] ?? []}
              settings={settings.testimonials}
            >
              {(item) => {
                return (
                  <Box w="100%" p="30px">
                    <VStack
                      w="100%"
                      alignContent="center"
                      justifyContent="center"
                    >
                      {item.hasImage && (
                        <Image
                          height="30px"
                          width="30px"
                          src={`${getServerUrl()}/index.php?class=ControllerAccess&method=getSystemContentImage&id=${
                            item.id
                          }`}
                          alt={item.title}
                        />
                      )}
                      <Box textAlign="justify">{item.body}</Box>
                    </VStack>
                  </Box>
                );
              }}
            </Carousel>
          </Box>
        )}

        {(items["sponsors"] ?? []).length > 0 && (
          <Box p="50px" w="75%">
            <HStack
              justifyContent={isLargeScreen ? "space-between" : "center"}
              m="30px"
              wrap="wrap"
            >
              <Box fontWeight="bold">Sponsors:</Box>
              <G2Button27
                text="Create an account"
                onClick={scrollToPricingTiers}
              />

              <Button>Become a Sponsor</Button>
            </HStack>
            <Carousel
              items={items["sponsors"] ?? []}
              settings={settings.sponsors}
            >
              {(item) => {
                return (
                  <Box w="100%" m="30px">
                    <HStack alignContent="center" justifyContent="center">
                      {item.hasImage && (
                        <Image
                          height="100px"
                          width="auto"
                          src={`${getServerUrl()}/index.php?class=ControllerAccess&method=getSystemContentImage&id=${
                            item.id
                          }`}
                          alt={item.title}
                        />
                      )}
                    </HStack>
                  </Box>
                );
              }}
            </Carousel>
          </Box>
        )}

        <Box>
          <HStack>
            <Box>
              <NavLink to="/about">About</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/overview">Overview</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/pricing">Pricing</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/contact">Customer Support</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/terms">Terms &amp; Conditions</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/privacy">Privacy Policy</NavLink>
            </Box>
          </HStack>
        </Box>

        <Box w="100%" className="p-5 text-center">
          <Box className="centering-w">
            <Box className="flex">
              {/* {social.map((val, i) => (
                <Image
                  key={i}
                  src={val}
                  width="25px"
                  height="25px"
                  className="mr-5 normal-hover"
                />
              ))} */}
              {social.map((item, i) => (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={i}
                >
                  <Image
                    src={item.src}
                    width="25px"
                    height="25px"
                    className="mr-5 normal-hover"
                  />
                </a>
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          padding="15px"
          marginTop="15px"
          w="100%"
          justifyContent="center"
          alignContent="center"
          marginX="auto"
          textAlign="center"
          backgroundColor="var(--blue)"
          color="white"
        >
          Copyright <UTradieText isHeading={true} /> PTY LTD
        </Box>
      </VStack>
    </Box>
  );
};

export default LandingPage;
