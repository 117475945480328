import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Image,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Link,
} from "@chakra-ui/react";
import _ from "lodash";
import { useStateContext } from "../../ContextProvider/Context";
import { socialMediaTypes } from "../../helpers/general"; // Ensure this path is correct

const SocialConnectForm = ({ userData, setUserData }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const [socialMedia, setSocialMedia] = useState(socialMediaTypes);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSocial, setSelectedSocial] = useState(null); // Track selected social media
  const [inputValue, setInputValue] = useState("");
  const [isEditing, setIsEditing] = useState(false); // Track editing mode

  // Initialize social media selection based on userData
  useEffect(() => {
    if (!userData?.socialMedia) {
      return;
    }

    let temp = _.cloneDeep(socialMedia);
    for (const name in userData.socialMedia) {
      if (temp[name]) {
        temp[name].isSelected = true;
        temp[name].url = userData.socialMedia[name];
      }
    }
    setSocialMedia(temp);
  }, [userData]);

  // Handle opening the modal and setting the selected social media
  const handleIconClick = (socialKey) => {
    setSelectedSocial(socialKey);
    setInputValue(socialMedia[socialKey].url || socialMedia[socialKey].default);
    setIsEditing(false); // Start in view mode
    onOpen();
  };

  // Handle updating selection and URL
  const handleSave = () => {
    // Update social media URL and selection
    let updatedSocialMedia = _.cloneDeep(socialMedia);
    updatedSocialMedia[selectedSocial].url = inputValue;
    updatedSocialMedia[selectedSocial].isSelected = inputValue !== "";

    setSocialMedia(updatedSocialMedia);

    // Update userData accordingly
    if (updatedSocialMedia[selectedSocial].isSelected) {
      updateUserData(["socialMedia", selectedSocial], inputValue);
    } else {
      updateUserData(["socialMedia", selectedSocial], undefined);
    }

    setIsEditing(false); // Exit editing mode
  };

  // Update userData with new URL
  const updateUserData = (path, value) => {
    let temp = _.cloneDeep(userData);
    temp = _.set(temp, path, value);
    setUserData(temp);
  };

  // Handle URL input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  return (
    <>
      {/* Social Media Icons List */}
      <Box display="flex" gap={4} justifyContent="flex-start" flexWrap="wrap">
        {Object.keys(socialMedia).map((key) => {
          const social = socialMedia[key];
          return (
            <Box key={key} textAlign="center">
              <Image
                src={social.img}
                borderRadius="full"
                boxSize="40px"
                cursor="pointer"
                onClick={() => handleIconClick(key)}
                border={
                  social.isSelected
                    ? "2px solid green"
                    : "2px solid transparent"
                }
                _hover={{ border: "2px solid blue" }}
                alt={social.name}
              />
            </Box>
          );
        })}
      </Box>

      {/* Modal for displaying and editing social media details */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedSocial && socialMedia[selectedSocial].name}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedSocial && (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={4}
                >
                  {/* Show either the link or input based on editing mode */}
                  {!isEditing ? (
                    <a
                      href={
                        socialMedia[selectedSocial].url ||
                        socialMedia[selectedSocial].default
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={socialMedia[selectedSocial].img}
                        boxSize="100px"
                        borderRadius="full"
                        alt={socialMedia[selectedSocial].name}
                        cursor="pointer"
                        _hover={{ transform: "scale(1.05)" }}
                        transition="transform 0.2s"
                      />
                    </a>
                  ) : (
                    <FormControl>
                      <FormLabel>URL</FormLabel>
                      <Input
                        type="url"
                        placeholder={socialMedia[selectedSocial].default}
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                  )}
                </Box>
                <Text mb={4}>{socialMedia[selectedSocial].description}</Text>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            {!isEditing ? (
              <Button colorScheme="blue" onClick={() => setIsEditing(true)}>
                Edit
              </Button>
            ) : (
              <Button colorScheme="blue" onClick={handleSave}>
                Save
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SocialConnectForm;
