import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  VStack,
  useToast,
  CheckboxGroup,
  Checkbox,
} from "@chakra-ui/react";

import { signUpUser } from "../../Store/Actions/authAction";
import g2Toast from "../../Custom/g2Toast";

const industryOptions = [
  "Agriculture and Farming",
  "Construction and Building",
  "Mechanical Trades",
  "Culinary Trades",
  "Fabrication and Manufacturing",
  // Add more industries as needed
];

const servicesOptions = {
  "Construction and Building": [
    "General Contracting",
    "Carpentry",
    "Electrical Installation",
    "Plumbing Services",
  ],
  "Mechanical Trades": ["Mechanic", "Welder", "Machinist"],
  "Culinary Trades": ["Chef", "Baker", "Catering"],
  // Add more services as needed
};

const joinReasonOptions = [
  "To upskill and train my workforce.",
  "To recruit skilled tradespeople.",
  "To access educational content for my employees.",
  "To explore industry trends and developments.",
  "To collaborate with other businesses and brands.",
];

const businessGoalsOptions = [
  "Improve workforce skills and knowledge.",
  "Recruit apprentices or experienced tradespeople.",
  "Access relevant educational content.",
  "Develop a stronger network of professionals.",
  "Increase business visibility in the industry.",
];

const BusinessForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const toast = useToast();
  const [formData, setFormData] = useState({
    businessName: "",
    businessAddress: "",
    businessEmail: "",
    password: "",
    confirmPassword: "",
    businessPhoneNumber: "",
    yearOfEstablishment: "",
    numberOfEmployees: "",
    contactPersonName: "",
    contactPersonEmail: "",
    contactPersonPhone: "",
    industry: "",
    services: [],
    joinReason: [],
    businessGoals: [],
    monetizeExpertise: "",
    createContent: "",
    exploreMarketplace: "",
    notificationPreferences: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (fieldName, value) => {
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const validateStep = () => {
    if (step === 1) {
      if (
        !formData.businessName ||
        !formData.businessAddress ||
        !formData.businessEmail ||
        !formData.password ||
        !formData.confirmPassword ||
        !formData.businessPhoneNumber ||
        !formData.yearOfEstablishment ||
        !formData.numberOfEmployees ||
        !formData.contactPersonName ||
        !formData.contactPersonEmail ||
        !formData.contactPersonPhone
      ) {
        g2Toast({ toast, status: "error", title: "Missing required input!" });
        return false;
      }
      if (
        !/^\S+@\S+\.\S+$/.test(formData.businessEmail) ||
        !/^\S+@\S+\.\S+$/.test(formData.contactPersonEmail)
      ) {
        g2Toast({ toast, status: "error", title: "Invalid Email address!" });
        return false;
      }

      if (formData.password !== formData.confirmPassword) {
        g2Toast({ toast, status: "error", title: "Passwords do not match!" });
        return;
      }
    } else if (step === 2) {
      if (!formData.industry) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select an industry!",
        });
        return false;
      }
      if (formData.services.length === 0) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select at least one service!",
        });
        return false;
      }
    } else if (step === 3) {
      if (formData.joinReason.length === 0) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select at least one reason for joining!",
        });
        return false;
      }
      if (formData.businessGoals.length === 0) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select at least one business goal!",
        });
        return false;
      }
      if (!formData.monetizeExpertise) {
        g2Toast({
          toast,
          status: "error",
          title: "Please specify if you want to monetize your expertise!",
        });
        return false;
      }
    } else if (step === 4) {
      if (!formData.createContent) {
        g2Toast({
          toast,
          status: "error",
          title: "Please specify if you want to create content!",
        });
        return false;
      }
      if (!formData.exploreMarketplace) {
        g2Toast({
          toast,
          status: "error",
          title: "Please specify if you want to explore the marketplace!",
        });
        return false;
      }
    } else if (step === 5) {
      if (!formData.notificationPreferences.length) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select at least one notification preference!",
        });
        return false;
      }
    }
    return true;
  };

  const nextStep = () => {
    if (validateStep()) setStep((prev) => prev + 1);
  };

  const prevStep = () => setStep((prev) => prev - 1);

  const handleSubmit = () => {
    const data = { ...formData, type: "Business", userTypeId: 2 };
    dispatch(signUpUser(toast, navigate, data));
  };

  return (
    <>
      {/* Step 1: Business Information */}
      {step === 1 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 1: Business Information
          </Text>
          <FormControl id="businessName" isRequired>
            <FormLabel>Business Name</FormLabel>
            <Input
              type="text"
              name="businessName"
              value={formData.businessName}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="businessAddress" isRequired>
            <FormLabel>Business Address</FormLabel>
            <Input
              type="text"
              name="businessAddress"
              value={formData.businessAddress}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="businessEmail" isRequired>
            <FormLabel>Business Email</FormLabel>
            <Input
              type="email"
              name="businessEmail"
              value={formData.businessEmail}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="confirmPassword" isRequired>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="businessPhoneNumber" isRequired>
            <FormLabel>Business Phone Number</FormLabel>
            <Input
              type="number"
              name="businessPhoneNumber"
              value={formData.businessPhoneNumber}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="yearOfEstablishment" isRequired>
            <FormLabel>Year of Establishment</FormLabel>
            <Input
              type="number"
              name="yearOfEstablishment"
              value={formData.yearOfEstablishment}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="numberOfEmployees" isRequired>
            <FormLabel>Number of Employees</FormLabel>
            <Select
              name="numberOfEmployees"
              value={formData.numberOfEmployees}
              onChange={handleInputChange}
            >
              <option value="">Select Number of Employees</option>
              <option value="1-10">1-10</option>
              <option value="11-50">11-50</option>
              <option value="51-200">51-200</option>
              <option value="201-500">201-500</option>
              <option value="500+">500+</option>
            </Select>
          </FormControl>
          <FormControl id="contactPersonName" isRequired>
            <FormLabel>Contact Person Name</FormLabel>
            <Input
              type="text"
              name="contactPersonName"
              value={formData.contactPersonName}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="contactPersonEmail" isRequired>
            <FormLabel>Contact Person Email</FormLabel>
            <Input
              type="email"
              name="contactPersonEmail"
              value={formData.contactPersonEmail}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="contactPersonPhone" isRequired>
            <FormLabel>Contact Person Phone Number</FormLabel>
            <Input
              type="number"
              name="contactPersonPhone"
              value={formData.contactPersonPhone}
              onChange={handleInputChange}
            />
          </FormControl>
          <Box className="w-full flex items-end justify-end">
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 2: Industry and Services */}
      {step === 2 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 2: Industry and Services
          </Text>
          <FormControl id="industry" isRequired>
            <FormLabel>Select Industry</FormLabel>
            <Select
              name="industry"
              value={formData.industry}
              onChange={handleInputChange}
            >
              <option value="">Select Industry</option>
              {industryOptions.map((industry) => (
                <option key={industry} value={industry}>
                  {industry}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl id="services" isRequired>
            <FormLabel>Select Services/Primary Business Focus</FormLabel>
            <CheckboxGroup
              onChange={(value) => handleCheckboxChange("services", value)}
            >
              <Stack spacing={4}>
                {formData.industry &&
                  servicesOptions[formData.industry]?.map((service) => (
                    <Checkbox key={service} value={service}>
                      {service}
                    </Checkbox>
                  ))}
              </Stack>
            </CheckboxGroup>
          </FormControl>
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 3: Business Goals and Objectives */}
      {step === 3 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 3: Business Goals and Objectives
          </Text>
          <FormControl id="joinReason" isRequired>
            <FormLabel>Why Did You Join UTRADIE?</FormLabel>
            <CheckboxGroup
              onChange={(value) => handleCheckboxChange("joinReason", value)}
            >
              <Stack spacing={4}>
                {joinReasonOptions.map((reason) => (
                  <Checkbox key={reason} value={reason}>
                    {reason}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </FormControl>
          <FormControl id="businessGoals" isRequired>
            <FormLabel>What Are Your Business Goals on UTRADIE?</FormLabel>
            <CheckboxGroup
              onChange={(value) => handleCheckboxChange("businessGoals", value)}
            >
              <Stack spacing={4}>
                {businessGoalsOptions.map((goal) => (
                  <Checkbox key={goal} value={goal}>
                    {goal}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </FormControl>
          <FormControl id="monetizeExpertise" isRequired>
            <FormLabel>
              Would You Like to Monetize Your Business Expertise by Offering
              Content on UTRADIE?
            </FormLabel>
            <RadioGroup
              name="monetizeExpertise"
              value={formData.monetizeExpertise}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, monetizeExpertise: value }))
              }
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 4: Marketplace and Content Creation */}
      {step === 4 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 4: Marketplace and Content Creation
          </Text>
          <FormControl id="createContent" isRequired>
            <FormLabel>
              Are You Interested in Creating and Selling Educational Content for
              Other UTRADIE Users?
            </FormLabel>
            <RadioGroup
              name="createContent"
              value={formData.createContent}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, createContent: value }))
              }
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl id="exploreMarketplace" isRequired>
            <FormLabel>
              Would You Like to Explore the Marketplace for Educational
              Materials?
            </FormLabel>
            <RadioGroup
              name="exploreMarketplace"
              value={formData.exploreMarketplace}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, exploreMarketplace: value }))
              }
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 5: Confirmation and Customization */}
      {step === 5 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 5: Confirmation and Customization
          </Text>
          <Box borderWidth={1} borderRadius="lg" p={4}>
            <Text fontWeight="bold">Review Your Details:</Text>
            <Text>Business Name: {formData.businessName}</Text>
            <Text>Business Address: {formData.businessAddress}</Text>
            <Text>Business Email: {formData.businessEmail}</Text>
            <Text>Business Phone Number: {formData.businessPhoneNumber}</Text>
            <Text>Year of Establishment: {formData.yearOfEstablishment}</Text>
            <Text>Number of Employees: {formData.numberOfEmployees}</Text>
            <Text>Contact Person Name: {formData.contactPersonName}</Text>
            <Text>Contact Person Email: {formData.contactPersonEmail}</Text>
            <Text>
              Contact Person Phone Number: {formData.contactPersonPhone}
            </Text>
            <Text>Industry: {formData.industry}</Text>
            <Text>Services: {formData.services.join(", ")}</Text>
            <Text>Join Reasons: {formData.joinReason.join(", ")}</Text>
            <Text>Business Goals: {formData.businessGoals.join(", ")}</Text>
            <Text>Monetize Expertise: {formData.monetizeExpertise}</Text>
            <Text>Create Content: {formData.createContent}</Text>
            <Text>Explore Marketplace: {formData.exploreMarketplace}</Text>
          </Box>
          <FormControl id="notificationPreferences" isRequired>
            <FormLabel>How would you like to receive updates?</FormLabel>
            <CheckboxGroup
              onChange={(value) =>
                handleCheckboxChange("notificationPreferences", value)
              }
            >
              <Stack spacing={4}>
                <Checkbox value="Email">Email</Checkbox>
                <Checkbox value="SMS">SMS</Checkbox>
                <Checkbox value="Push Notifications">
                  Push Notifications
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button
              colorScheme="orange"
              onClick={() => {
                if (validateStep()) {
                  handleSubmit();
                }
              }}
            >
              Sign up
            </Button>
          </Box>
        </VStack>
      )}
    </>
  );
};

export default BusinessForm;
