import g2Axios from "../../Custom/g2Axios";
import g2AxiosT from "../../Custom/g2AxiosT";
import g2Toast from "../../Custom/g2Toast";
import { getToken, setToken } from "../../utils";

const signUpUser = (toast, navigate, newUser) => {
  return (dispatch) => {
    g2Axios
      .post("/signup", newUser)
      .then((res) => {
        if (Boolean(res.data.user)) {
          dispatch({ type: "AUTH_SET_DES", payload: "" });
          g2Toast({ toast, status: "success", title: res.data.msg });
          navigate("/login");
        } else {
          g2Toast({ toast, status: "error", title: res.data });
        }
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error registering user:", error);
      });
  };
};

const forgotPassword = (toast, email, isBusiness) => {
  return (dispatch) => {
    g2Axios
      .post("/", {
        class: "ControllerAccess",
        method: "createForgottenPasswordRequestForEmail",
        email,
      })
      .then((res) => {
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error registering user:", error);
      });
  };
};

const logInUser = (toast, navigate, email, password, isBusiness) => {
  return (dispatch) => {
    g2Axios
      .post("/login", { email, password, isBusiness })
      .then((res) => {
        if (Boolean(res.data.user)) {
          dispatch({ type: "AUTH_GET_USER_DATA", payload: res.data.user });
          dispatch({ type: "AUTH_GET_USER_TYPE", payload: res.data.type });
          dispatch({ type: "AUTH_GET_USERDATA", payload: res.data.userData });
          dispatch({ type: "AUTH_GET_TODAY", payload: res.data.today });

          g2Toast({ toast, status: "success", title: res.data.msg });
          setToken(res.data.token);
          if (getToken()) {
            console.log(res.data);
            if (res.data.isFirst) {
              navigate("/subscription");
            } else {
              navigate("/dashboard");
            }
          }
        } else {
          g2Toast({ toast, status: "error", title: res.data });
        }
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error fetching user data:", error);
      });
  };
};

const logInDashboard = (toast, navigate, email, password, isBusiness) => {
  return (dispatch) => {
    g2Axios
      .post("/login", { email, password, isBusiness })
      .then((res) => {
        if (Boolean(res.data.user)) {
          dispatch({ type: "AUTH_GET_USER_DATA", payload: res.data.user });
          dispatch({ type: "AUTH_GET_USER_TYPE", payload: res.data.type });
          dispatch({ type: "AUTH_GET_USERDATA", payload: res.data.userData });
          dispatch({ type: "AUTH_GET_TODAY", payload: res.data.today });

          g2Toast({ toast, status: "success", title: res.data.msg });
          setToken(res.data.token);
          navigate("/dashboard");
        } else {
          g2Toast({ toast, status: "error", title: res.data });
        }
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error fetching user data:", error);
      });
  };
};

const logInCreate = (toast, navigate, email, password, isBusiness) => {
  return (dispatch) => {
    g2Axios
      .post("/login", { email, password, isBusiness })
      .then((res) => {
        if (Boolean(res.data.user)) {
          dispatch({ type: "AUTH_GET_USER_DATA", payload: res.data.user });
          dispatch({ type: "AUTH_GET_USER_TYPE", payload: res.data.type });
          dispatch({ type: "AUTH_GET_USERDATA", payload: res.data.userData });
          dispatch({ type: "AUTH_GET_TODAY", payload: res.data.today });

          g2Toast({ toast, status: "success", title: res.data.msg });
          setToken(res.data.token);
          navigate("/create");
        } else {
          g2Toast({ toast, status: "error", title: res.data });
        }
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error fetching user data:", error);
      });
  };
};

const logInLearn = (toast, navigate, email, password, isBusiness) => {
  return (dispatch) => {
    g2Axios
      .post("/login", { email, password, isBusiness })
      .then((res) => {
        if (Boolean(res.data.user)) {
          dispatch({ type: "AUTH_GET_USER_DATA", payload: res.data.user });
          dispatch({ type: "AUTH_GET_USER_TYPE", payload: res.data.type });
          dispatch({ type: "AUTH_GET_USERDATA", payload: res.data.userData });
          dispatch({ type: "AUTH_GET_TODAY", payload: res.data.today });

          g2Toast({ toast, status: "success", title: res.data.msg });
          setToken(res.data.token);
          navigate("/learn");
        } else {
          g2Toast({ toast, status: "error", title: res.data });
        }
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error fetching user data:", error);
      });
  };
};

const logInAcademy = (toast, navigate, email, password, isBusiness) => {
  return (dispatch) => {
    g2Axios
      .post("/login", { email, password, isBusiness })
      .then((res) => {
        if (Boolean(res.data.user)) {
          dispatch({ type: "AUTH_GET_USER_DATA", payload: res.data.user });
          dispatch({ type: "AUTH_GET_USER_TYPE", payload: res.data.type });
          dispatch({ type: "AUTH_GET_USERDATA", payload: res.data.userData });
          dispatch({ type: "AUTH_GET_TODAY", payload: res.data.today });

          g2Toast({ toast, status: "success", title: res.data.msg });
          setToken(res.data.token);
          navigate("/academy/digital");
        } else {
          g2Toast({ toast, status: "error", title: res.data });
        }
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error fetching user data:", error);
      });
  };
};

const goToLogin = (navigate, des) => {
  return (dispatch) => {
    dispatch({ type: "AUTH_SET_DES", payload: des });
    navigate("/login");
  };
};

const getUserData = (type) => {
  return (dispatch) => {
    g2AxiosT
      .get("/getuser/" + type)
      .then((res) => {
        dispatch({ type: "AUTH_GET_USER_DATA", payload: res.data.user });
        dispatch({ type: "AUTH_GET_USER_TYPE", payload: res.data.type });
        dispatch({ type: "AUTH_GET_USERDATA", payload: res.data.userData });
        dispatch({ type: "AUTH_GET_TODAY", payload: res.data.today });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };
};

const makeUserData = (toast, user, msg) => {
  return (dispatch) => {
    dispatch({ type: "AUTH_GET_USER_DATA", payload: user });
    g2Toast({ toast, status: "success", title: msg });
  };
};

const verifyEmail = (toast, navigate, token) => {
  return (dispatch) => {
    g2Axios
      .post("/", { class: "ControllerAccess", method: "verifyEmail", token })
      .then((res) => {
        if (Boolean(res.data.user)) {
          dispatch({ type: "AUTH_GET_USER_DATA", payload: res.data.user });
          dispatch({ type: "AUTH_GET_USER_TYPE", payload: res.data.type });
          dispatch({ type: "AUTH_GET_USERDATA", payload: res.data.userData });
          dispatch({ type: "AUTH_GET_TODAY", payload: res.data.today });

          g2Toast({ toast, status: "success", title: res.data.msg });
          setToken(res.data.token);
          navigate("/dashboard");
        } else {
          g2Toast({ toast, status: "error", title: res.data });
        }
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error fetching user data:", error);
      });
  };
};

const forgotPasswordRequest = (toast, navigate, password, token) => {
  return (dispatch) => {
    g2Axios
      .post("/", {
        class: "ControllerAccess",
        method: "forgottenPasswordReset",
        password,
        token,
      })
      .then((res) => {
        if (Boolean(res.data.user)) {
          dispatch({ type: "AUTH_GET_USER_DATA", payload: res.data.user });
          dispatch({ type: "AUTH_GET_USER_TYPE", payload: res.data.type });
          dispatch({ type: "AUTH_GET_USERDATA", payload: res.data.userData });
          dispatch({ type: "AUTH_GET_TODAY", payload: res.data.today });

          g2Toast({ toast, status: "success", title: res.data.msg });
          setToken(res.data.token);
          navigate("/dashboard");
        } else {
          g2Toast({ toast, status: "error", title: res.data });
        }
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error fetching user data:", error);
      });
  };
};

export {
  signUpUser,
  forgotPassword,
  logInUser,
  logInDashboard,
  logInCreate,
  logInLearn,
  logInAcademy,
  goToLogin,
  getUserData,
  makeUserData,
  verifyEmail,
  forgotPasswordRequest,
};
