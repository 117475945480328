import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import plan from "../../assets/create/plan.png";

import {
  Box,
  Image,
  Text,
  List,
  ListItem,
  ListIcon,
  Boxider,
  IconButton,
  useToast,
  VStack,
  HStack,
  Button,
} from "@chakra-ui/react";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, deleteToken, verifyToken } from "../../utils";
import g2Toast from "../../Custom/g2Toast";
import isEmptyValue from "../../Custom/isEmptyValue";
import G2Button24 from "../Common/G2Button24";
import G2Button25 from "../Common/G2Button25";

import { getFileUrl, socialMediaTypes } from "../../helpers/general";
import { followUser } from "../../Store/Actions/academyAction";
import { FaPassport, FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import Chat from "../Chat/Chat";
import SearchBox from "./SearchBox";

const LeftSideBar = ({
  leftBarShow,
  leftBarClick,
  passport,
  setPassport,
  saveCallback,
}) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();
  const user = useSelector((state) => state.Auth.user);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const token = getToken();

  const isProfile = useMemo(() => {
    return passport?.id == user?.id ?? false;
  }, [user, passport]);

  useEffect(() => {
    console.log("User:", user);
    console.log("Passport:", passport);
  }, [user, passport]);

  const calcAge = () => {
    const currentDate = new Date();
    const year1 = parseInt(currentDate.getFullYear());

    const year2 = parseInt(passport?.data?.birthday?.slice(0, 4));

    return year1 - year2;
  };

  const requestText = useMemo(() => {
    if (passport?.isRejected) {
      return "Rejected";
    }

    if (passport?.isAccepted) {
      return "Accepted";
    }

    if (passport?.isRequesting) {
      return "Waiting on Request";
    }
  }, [passport]);

  const canRequestMore = useMemo(() => {
    if (!passport?.isFollowing) {
      return false;
    }

    if (passport?.isRejectedCanReapply) {
      return true;
    }

    if (
      passport?.isAccepted ||
      passport?.isRejected ||
      passport?.isRequesting
    ) {
      return false;
    }

    return true;
  }, [passport, isProfile]);

  const showRequestState = useMemo(() => {
    if (
      !passport?.isFollowing ||
      !passport?.isRequesting ||
      passport?.isRejected
    ) {
      return false;
    }

    if (passport?.isAccepted && passport?.isRejected) {
      return true;
    }

    return false;
  }, [passport, isProfile]);

  return (
    <>
      <Box
        w="100%"
        h="80px"
        bg={G2_color_2}
        className="flex-box centering-h px-[30px] shadow"
        justifyContent="space-between"
      >
        <Box className="flex-box text-left centering-h">
          <Image boxSize="30px" src={plan} alt="icon" />
          <Text fontSize="16px" className="font-semibold mx-[10px]">
            {user?.type === "Business" ? "Digital Profile" : "Digital Passport"}
          </Text>
        </Box>
        <Box className="flex-box text-right centering-h">
          <Box className="mr-5 flex">
            <SearchBox />
          </Box>
        </Box>
      </Box>

      <Box
        className="w-full h-52"
        style={{ background: "#49688f", color: "#ffffff" }}
      >
        <Box w="100%" h="100%" className="w-full items-center justify-center">
          <Box className="h-5">
            {!passport?.isMyProfile && (
              <NavLink to="/academy/digital">
                <IconButton
                  isRound={true}
                  className="normal-hover"
                  style={{ backgroundColor: "var(--orange)" }}
                  color="white"
                  aria-label="Done"
                  fontSize="20px"
                  icon={<ArrowBackIcon />}
                />
              </NavLink>
            )}
          </Box>
          <Box className="px-[8%] flex text-white  items-center w-full justify-between">
            <Box className="w-full items-center justify-center ">
              <Image
                borderRadius="full"
                bg="white"
                boxSize="100px"
                src={getFileUrl(passport?.avatar)}
                className="ml-5 mb-3"
                alt="avatar"
              />
              <Text fontSize="20px" className="font-bold">
                {passport?.name}
              </Text>
              <Text
                w="100%"
                fontSize="14px"
                className="mb-[5px] font-semibold "
              >
                {passport?.followers} followers | {passport?.following}{" "}
                following
              </Text>
            </Box>
            <Box className="w-full items-center justify-center ">
              {passport?.data?.companyName !== undefined &&
                passport?.data?.companyName != "" && (
                  <Text
                    w="100%"
                    fontSize="16px"
                    className="text-center font-semibold"
                  >
                    <Text className="inline" color="var(--orange)">
                      Works For:{" "}
                    </Text>
                    {passport?.data?.companyName}
                  </Text>
                )}
              {passport?.data?.industry !== undefined &&
                passport?.data?.industry != "" && (
                  <Text
                    w="100%"
                    fontSize="16px"
                    className="text-center font-semibold"
                  >
                    <Text className="inline" color="var(--orange)">
                      Industry:{" "}
                    </Text>
                    {passport?.data?.industry}
                  </Text>
                )}
              {passport?.data?.birthday !== undefined && (
                <Text
                  w="100%"
                  fontSize="16px"
                  className=" text-center font-semibold"
                >
                  <Text className="inline" color="var(--orange)">
                    Age:{" "}
                  </Text>
                  {calcAge()}
                </Text>
              )}
              {passport?.data?.address !== undefined && (
                <Text
                  w="100%"
                  fontSize="16px"
                  className=" text-center mb-[10px] font-semibold"
                >
                  <Text className="inline" color="var(--orange)">
                    Location:{" "}
                  </Text>
                  {passport?.data?.address?.split(",").pop()}
                </Text>
              )}
            </Box>
            {
              //JSON.stringify(canRequestMore)
            }
            <Box
              className="w-full items-center "
              style={{ position: "relative" }}
            >
              <Box style={{ float: "right" }}>
                {isProfile == true && (
                  <G2Button24
                    text={
                      (passport?.profile?.isPrivate ?? true) == true
                        ? "Private"
                        : "Public"
                    }
                    onClick={() =>
                      setPassport(
                        ["profile", "isPrivate"],
                        !(passport?.profile?.isPrivate ?? true)
                      )
                    }
                  />
                )}
                {isProfile == false && (
                  <>
                    <G2Button24
                      text={passport?.isFollowing ? "- Follow" : "+ Follow"}
                      onClick={() =>
                        dispatch(
                          followUser(
                            toast,
                            passport.hashId,
                            !(passport?.isFollowing ?? false),
                            false
                          )
                        )
                      }
                    />
                    <Chat targetType="user" targetId={passport?.id}>
                      <G2Button25 text="+ Message" />
                    </Chat>
                  </>
                )}
                {canRequestMore && (
                  <G2Button24
                    text={"Request More"}
                    onClick={() =>
                      dispatch(followUser(toast, passport.hashId, true, true))
                    }
                  />
                )}
                {showRequestState && <G2Button24 text={requestText} />}

                {isProfile == true && (
                  <G2Button24 text="Save" onClick={saveCallback} />
                )}
              </Box>
            </Box>
            {/* <Box>
              <Box className="flex mt-[50px]">
                {Object.keys(socialMediaTypes).map((name, i) => {
                  if (
                    passport?.data?.socialMedia === undefined ||
                    passport?.data?.socialMedia[name] === undefined
                  ) {
                    return "";
                  }

                  return (
                    <Link key={i} to={passport?.data?.socialMedia[name]}>
                      <Image
                        src={socialMediaTypes[name].img}
                        width="30px"
                        height="30px"
                        className="ml-[5px] mr-[5px] normal-hover"
                      />
                    </Link>
                  );
                })}
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LeftSideBar;
