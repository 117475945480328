// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// import {
//   Box,
//   Text,
//   Image,
//   Divider,
//   Card,
//   CardBody,
//   Select,
//   Progress,
//   Circle,
//   useToast,
//   InputGroup,
//   Input,
//   InputRightElement,
//   IconButton,
//   Checkbox,
//   Textarea,
// } from "@chakra-ui/react";

// import {
//   ArrowLeftIcon,
//   ArrowRightIcon,
//   LockIcon,
//   ViewIcon,
//   EditIcon,
//   DeleteIcon,
// } from "@chakra-ui/icons";

// import "../../App.css";

// import { useStateContext } from "../../ContextProvider/Context";
// import { getToken, verifyToken } from "../../utils";
// import {
//   getEventList,
//   getEvent,
//   makeEvent,
//   deleteEvent,
// } from "../../Store/Actions/academyAction";
// import g2Toast from "../../Custom/g2Toast";
// import isEmptyObject from "../../Custom/isEmptyObject";
// import isEmptyValue from "../../Custom/isEmptyValue";

// import G2ConfirmAlert from "../Common/G2ConfirmAlert";

// // import AddModal from "./AddModal";

// import G2Button10 from "../Common/G2Button10";
// import G2Button20 from "../Common/G2Button20";
// import G2Button21 from "../Common/G2Button21";
// import G2Button23 from "../Common/G2Button23";

// import event from "../../assets/academy/event.png";
// import { getServerUrl } from "../../helpers/location";
// import { getFileUrl } from "../../helpers/general";

// const EventBoardForm = (props) => {
//   const { screenSize, boxW1, boxW2 } = props;

//   const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
//     useStateContext();

//   const navigate = useNavigate();

//   const dispatch = useDispatch();

//   const toast = useToast();

//   const eventList = useSelector((state) => state.Academy.eventList);

//   const [isEventDopen, setIsEventDopen] = useState(false);
//   const [delId, setDelId] = useState("");

//   const [type, setType] = useState("All");
//   const [today, setToday] = useState("");
//   const [month, setMonth] = useState("");
//   const [year, setYear] = useState("");
//   const [sortedData, setSortedData] = useState([]);
//   console.log("++++++++++", sortedData);
//   const token = getToken();

//   useEffect(() => {
//     if (verifyToken()) {
//       dispatch(getEventList(toast));
//     }

//     const currentDate = new Date();
//     const year = currentDate.getFullYear();
//     const month = String(currentDate.getMonth() + 1).padStart(2, "0");
//     const day = String(currentDate.getDate()).padStart(2, "0");
//     const formattedDate = `${year}-${month}-${day}`;
//     const formattedMonth = `${year}-${month}`;
//     const formattedYear = `${year}-`;

//     setToday(formattedDate);
//     setMonth(formattedMonth);
//     setYear(formattedYear);
//   }, []);

//   useEffect(() => {
//     setSortedData(eventList);
//   }, [eventList]);

//   const openEventDModal = (id) => {
//     setIsEventDopen(true);
//     setDelId(id);
//   };

//   const closeEventDModal = () => {
//     setIsEventDopen(false);
//   };

//   const handleEventCreateClick = () => {
//     navigate("/academy/event/create");
//   };

//   const handleEventViewClick = (val) => {
//     dispatch(getEvent(navigate, val));
//   };

//   const handleUpdateClick = (val) => {
//     dispatch(makeEvent(navigate, val));
//   };

//   const handleDeleteClick = () => {
//     dispatch(deleteEvent(toast, delId));

//     setIsEventDopen(false);
//   };

//   const handleTypeChange = (e) => {
//     const value = e.target.value;
//     setType(value);

//     if (value == "All") {
//       setSortedData(eventList);
//     } else if (value == "Today") {
//       setSortedData(eventList?.filter((val) => val.date == "z"));
//     } else if (value == "This Month") {
//       setSortedData(eventList?.filter((val) => val.date?.includes(month)));
//     } else {
//       setSortedData(eventList?.filter((val) => val.date?.includes(year)));
//     }
//   };

//   return (
//     <Box
//       w="100%"
//       h="100%"
//       style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
//     >
//       <Box
//         w="100%"
//         h="80px"
//         bg={G2_color_2}
//         className="flex-box centering-h px-[30px] shadow"
//       >
//         <Text
//           fontSize="20px"
//           className="font-semibold mr-[10px] text-[var(--orange)]"
//         >
//           Events Boards
//         </Text>
//       </Box>
//       <Box w="100%" px="30px" py="20px" className="flex-box">
//         <Text
//           w="100%"
//           fontSize="20px"
//           className="mt-[10px] font-semibold text-center"
//         >
//           Find events
//         </Text>
//         <Box w={boxW1} className="flex mt-5">
//           <Select
//             w="130px"
//             borderRadius="2.5px"
//             height="35px"
//             style={{
//               background: `${G2_color_2}`,
//               color: `${G2_color_3}`,
//               borderRadius: "2.5px",
//             }}
//             value={type}
//             onChange={handleTypeChange}
//           >
//             <option
//               value="All"
//               style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
//             >
//               All
//             </option>
//             <option
//               value="Today"
//               style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
//             >
//               Today
//             </option>
//             <option
//               value="This Month"
//               style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
//             >
//               This Month
//             </option>
//             <option
//               value="This Year"
//               style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
//             >
//               This Year
//             </option>
//           </Select>
//         </Box>
//         <Box w={boxW1} className="flex mt-[10px] justify-end">
//           <G2Button23
//             widthVal="150px"
//             heightVal="35px"
//             text="Create a Event"
//             onClick={handleEventCreateClick}
//           />
//         </Box>
//         {/* show in modal */}
//         <Box w="100%">
//           {sortedData && (
//             <Box w="100%" className="flex-box items-center justify-center">
//               {sortedData?.map((val, i) => (
//                 <Card
//                   key={i}
//                   w={boxW2}
//                   borderRadius="2.5px"
//                   className="relative mt-5 mr-5"
//                   style={{
//                     background: `${G2_color_2}`,
//                     color: `${G2_color_3}`,
//                   }}
//                 >
//                   <CardBody w="100%">
//                     <Image w="100%" h="300px" src={getFileUrl(val.photo)} />
//                     <Box w="100%" className="flex-box space-y-0">
//                       <Text
//                         w="100%"
//                         fontSize="28px"
//                         className="font-semibold  text-center"
//                       >
//                         {val.name}
//                       </Text>
//                       <Text
//                         w="100%"
//                         fontSize="14px"
//                         className="font-semibold text-center"
//                       >
//                         {val.email}
//                       </Text>
//                       <Text w="100%" fontSize="16px" className="text-center">
//                         {val.location}
//                       </Text>
//                       <Text
//                         w="100%"
//                         fontSize="20px"
//                         className="mt-[5px] font-normal  text-center text-red-500"
//                       >
//                         {val.date} {val.time}
//                       </Text>
//                       <Text w="100%" fontSize="16px" className="text-center">
//                         {val.description}
//                       </Text>
//                     </Box>
//                     <Box className="flex items-center justify-center mt-5 w-full">
//                       <G2Button20
//                         widthVal="120px"
//                         heightVal="35px"
//                         text="RSVP"
//                         onClick={() => handleEventViewClick(val)}
//                       />
//                     </Box>
//                     {/* <Box
//                       w="100%"
//                       bg="#334F72"
//                       className="mt-[10px] pt-[10px] pb-[5px] centering-w hidden"
//                     >
//                       <ViewIcon
//                         color="var(--orange)"
//                         className="mr-10 mb-[4px] normal-hover"
//                         onClick={() => handleEventViewClick(val)}
//                       />
//                       <EditIcon
//                         color="white"
//                         className="mr-10 mb-[5px] normal-hover"
//                         onClick={() => handleUpdateClick(val)}
//                       />
//                       <DeleteIcon
//                         color="#d86655"
//                         className="mb-[5px] normal-hover"
//                         onClick={() => openEventDModal(val._id)}
//                       />
//                     </Box> */}
//                     <G2ConfirmAlert
//                       isOpen={isEventDopen}
//                       onClose={closeEventDModal}
//                       handleOkClick={handleDeleteClick}
//                     />
//                   </CardBody>
//                 </Card>
//               ))}
//             </Box>
//           )}
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default EventBoardForm;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Card,
  CardBody,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";

import { ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";

import "../../App.css";
import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";
import {
  getEventList,
  getEvent,
  makeEvent,
  deleteEvent,
} from "../../Store/Actions/academyAction";
import g2Toast from "../../Custom/g2Toast";
import G2Button20 from "../Common/G2Button20";
import G2ConfirmAlert from "../Common/G2ConfirmAlert";

import { getFileUrl } from "../../helpers/general";

const EventBoardForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const eventList = useSelector((state) => state.Academy.eventList);
  const [isEventDopen, setIsEventDopen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [type, setType] = useState("All");
  const [sortedData, setSortedData] = useState([]);
  const token = getToken();

  useEffect(() => {
    if (verifyToken()) {
      dispatch(getEventList(toast));
    }
    setSortedData(eventList);
  }, [eventList]);

  const openEventDModal = (id) => {
    setIsEventDopen(true);
  };

  const closeEventDModal = () => {
    setIsEventDopen(false);
  };

  const handleEventCreateClick = () => {
    navigate("/academy/event/create");
  };

  const handleEventViewClick = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
  };

  const handleRSVP = () => {
    toast({
      title: "RSVP Success!",
      description: "You have successfully RSVP'd for this event.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setSelectedEvent(null);
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;
    setType(value);

    if (value === "All") {
      setSortedData(eventList);
    } else if (value === "Today") {
      // Filter by today's date
    } else if (value === "This Month") {
      // Filter by this month
    } else {
      // Filter by this year
    }
  };

  return (
    <Box
      w="100%"
      h="100%"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box
        w="100%"
        h="80px"
        bg={G2_color_2}
        className="flex-box centering-h px-[30px] shadow"
      >
        <Text
          fontSize="20px"
          className="font-semibold mr-[10px] text-[var(--orange)]"
        >
          Events Boards
        </Text>
      </Box>

      <Box w="100%" px="30px" py="20px" className="flex-box">
        <Text
          w="100%"
          fontSize="20px"
          className="mt-[10px] font-semibold text-center"
        >
          Find events
        </Text>
        <Box w={boxW1} className="flex mt-5">
          <Select
            w="130px"
            borderRadius="2.5px"
            height="35px"
            style={{
              background: `${G2_color_2}`,
              color: `${G2_color_3}`,
              borderRadius: "2.5px",
            }}
            value={type}
            onChange={handleTypeChange}
          >
            <option
              value="All"
              style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
            >
              All
            </option>
            <option
              value="Today"
              style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
            >
              Today
            </option>
            <option
              value="This Month"
              style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
            >
              This Month
            </option>
            <option
              value="This Year"
              style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
            >
              This Year
            </option>
          </Select>
        </Box>
        <Box w={boxW1} className="flex mt-[10px] justify-end">
          <Button
            width="150px"
            height="35px"
            colorScheme="orange"
            onClick={handleEventCreateClick}
          >
            Create an Event
          </Button>
        </Box>
      </Box>

      <Box w="100%">
        {sortedData && (
          <Box w="100%" className="flex-box ">
            {sortedData?.map((val, i) => (
              <Card
                key={i}
                // w={300}
                borderRadius="2.5px"
                className="relative mt-5 mx-5 w-[20%] p-5"
                style={{
                  background: `${G2_color_2}`,
                  color: `${G2_color_3}`,
                }}
              >
                <CardBody w="100%" className="bg-slate-300">
                  <Image w="100%" h="150px" src={getFileUrl(val.photo)} />
                  <Box w="100%" className="flex-box space-y-0">
                    <Text
                      w="100%"
                      fontSize="16px"
                      className="font-normal text-center"
                    >
                      {val.date} {val.time}
                    </Text>
                    <Text
                      w="100%"
                      fontSize="18px"
                      className="font-semibold text-center"
                    >
                      {val.name}
                    </Text>
                    <Text w="100%" fontSize="16px" className="text-center">
                      {val.location}
                    </Text>
                  </Box>
                  <Box className="flex items-center justify-center mt-5 w-full">
                    <Button
                      colorScheme="blue"
                      onClick={() => handleEventViewClick(val)}
                    >
                      View
                    </Button>
                  </Box>
                </CardBody>
              </Card>
            ))}
          </Box>
        )}
      </Box>

      {/* Modal for selected event */}
      {selectedEvent && (
        <Modal isOpen={true} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedEvent.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Image w="100%" h="300px" src={getFileUrl(selectedEvent.photo)} />
              <Text fontSize="24px" mt="4" fontWeight="bold">
                {selectedEvent.name}
              </Text>
              <Text fontSize="16px" mt="2">
                Location: {selectedEvent.location}
              </Text>
              <Text fontSize="16px" mt="2" color="red.500">
                Date & Time: {selectedEvent.date} {selectedEvent.time}
              </Text>
              <Text fontSize="18px" mt="4" fontWeight="bold">
                {selectedEvent.description}
              </Text>
            </ModalBody>
            <ModalFooter display="flex" justifyContent="center" w="100%">
              <G2Button20
                widthVal="120px"
                heightVal="35px"
                text="RSVP"
                mr={3}
                onClick={handleRSVP}
              >
                Close
              </G2Button20>
              {/* <Box
                w="100%"
                bg="#334F72"
                className="mt-[10px] pt-[10px] pb-[5px] centering-w hidden"
              >
                <ViewIcon
                  color="var(--orange)"
                  className="mr-10 mb-[4px] normal-hover"
                  onClick={() => handleEventViewClick(val)}
                />
                <EditIcon
                  color="white"
                  className="mr-10 mb-[5px] normal-hover"
                  onClick={() => handleUpdateClick(val)}
                />
                <DeleteIcon
                  color="#d86655"
                  className="mb-[5px] normal-hover"
                  onClick={() => openEventDModal(val._id)}
                />
              </Box> */}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default EventBoardForm;
